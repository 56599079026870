import { formatToIsoString, formatToIsoStringEndOfDay } from '@/services/shared';
import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';
import { OK } from '@/constants/shared';

const fileName = 'extraction.api.js';

export async function getExtractionUnavailabilities(
    creationDate,
    startDate,
    endDate,
    selectedReasons,
    selectedStatuses,
    isProofNeeded,
    selectedPerimeter,
    selectedBeneficiaries,
    sortBy = 'date_created',
    sortDesc = false,
    offset,
    limit
) {
    const params = new URLSearchParams();
    if (creationDate && creationDate.length) {
        params.append('date_created', creationDate);
    }
    if (startDate && startDate.length) {
        params.append('date_debut', formatToIsoString(startDate));
    }
    if (endDate && endDate.length) {
        params.append('date_fin', formatToIsoStringEndOfDay(endDate));
    }
    if (selectedReasons && selectedReasons.length) {
        params.append('indisponibilites', selectedReasons);
    }
    if (selectedStatuses && selectedStatuses.length) {
        params.append('status', selectedStatuses);
    }
    if (isProofNeeded) {
        params.append('proof_needed', OK);
    }
    if (selectedPerimeter && selectedPerimeter.length) {
        params.append('eor_codes', selectedPerimeter);
    }
    if (selectedBeneficiaries && selectedBeneficiaries.length) {
        params.append('gid_collaborateur', selectedBeneficiaries);
    }
    if (sortBy && sortBy.length) {
        params.append('order', sortBy[0]);
    }
    if (sortDesc && sortDesc.length) {
        params.append('asc', !sortDesc[0]);
    }
    params.append('limit', limit);
    params.append('offset', offset);

    try {
        const response = await apiClient.get('/unavailabilities/extraction', { params });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.EXTRACTION_UNAVAILABILITIES, fileName)
        );
    }
}

export default {
    getExtractionUnavailabilities,
};
