const EXTRACTION_TABLE_HEADERS = [
    {
        text: 'Statut',
        value: 'statut',
        class: 'header',
        width: '85px',
    },
    {
        text: 'Date de la demande',
        value: 'date_created',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Bénéficiaire',
        value: 'nom',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Périmètre',
        value: 'eor_cd',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Début indisponibilité',
        value: 'date_debut',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Fin indisponibilité',
        value: 'date_fin',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Motif',
        value: 'indisponibilite',
        class: 'header',
        width: '155px',
    },
    {
        text: 'Actions',
        value: 'actions',
        class: 'header',
        width: '185px',
    },
];

export default EXTRACTION_TABLE_HEADERS;
