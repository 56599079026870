<template>
    <v-container fluid>
        <p>Paramétrage d'extraction</p>
        <v-row class="extraction-filters">
            <v-col>
                <v-row>
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            prepend-icon="mdi-calendar"
                            label="Date de demande"
                            v-model="unavailabilityCreationDate"
                            :max="todayDate"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            prepend-icon="mdi-calendar"
                            label="Début d'indisponibilité"
                            v-model="unavailabilityStartDate"
                            :max="unavailabilityEndDate"
                        />
                    </v-col>
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            prepend-icon="mdi-calendar"
                            label="Fin d'indisponibilité"
                            v-model="unavailabilityEndDate"
                            :min="unavailabilityStartDate"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col offset="1">
                <TextSelectPicker
                    clearable
                    dense
                    no-data-text="Aucune donnée à afficher."
                    v-model="selectedReasons"
                    :items="reasons"
                    label="Motif"
                    multiple
                    :maxChipsDisplayed="1"
                    deletableChips
                />

                <TextSelectPicker
                    clearable
                    dense
                    no-data-text="Aucune donnée à afficher."
                    v-model="selectedStatuses"
                    :items="statuses"
                    label="Statut"
                    multiple
                    :maxChipsDisplayed="2"
                    deletableChips
                />
                <v-switch v-model="isProofNeeded" label="Indisponibilité soumise à justificatif" />
            </v-col>
            <v-col offset="1">
                <TextSelectPicker
                    clearable
                    dense
                    no-data-text="Aucune donnée à afficher."
                    v-model="selectedPerimeter"
                    :items="perimeterEntities"
                    label="Périmètre"
                    item-text="nom"
                    item-value="id"
                    multiple
                    chipLabel="id"
                    :maxChipsDisplayed="3"
                    deletableChips
                />

                <GroupSelectionPicker
                    clearable
                    dense
                    no-data-text="Aucune donnée à afficher."
                    :population="perimeterPeople"
                    v-model="selectedBeneficiaries"
                    label="Bénéficiaires"
                    multiple
                    :maxChipsDisplayed="1"
                    deletableChips
                />

                <v-row justify="space-between">
                    <v-col>
                        <v-btn
                            block
                            depressed
                            tile
                            outlined
                            :disabled="isLoading"
                            @click="resetFilters()"
                        >
                            Réinitialiser les filtres
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            block
                            depressed
                            tile
                            class="primary"
                            :disabled="isLoading"
                            @click="updateFilters()"
                        >
                            Rafraîchir les résultats
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';

import TextDatePicker from '@/components/pickers/TextDatePicker.vue';
import TextSelectPicker from '@/components/pickers/TextSelectPicker.vue';
import GroupSelectionPicker from '@/components/pickers/GroupSelectionPicker.vue';

import { ISO_DATE_FORMAT } from '@/constants/dateFormat';
import { STATUS_TEXT_SELECT_DATA, NO } from '@/constants/shared';
import { removeDuplicates } from '@/services/shared';

export default {
    name: 'ExtractionFilters',

    components: {
        TextDatePicker,
        TextSelectPicker,
        GroupSelectionPicker,
    },

    props: {
        perimeterEntities: {
            type: Array,
            required: true,
        },
        perimeterPeople: {
            type: Array,
            required: true,
        },
        unavailabilityReasons: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            todayDate: moment().format(ISO_DATE_FORMAT),
            unavailabilityCreationDate: '',
            unavailabilityStartDate: '',
            unavailabilityEndDate: '',

            selectedReasons: [],

            selectedStatuses: [],
            statuses: STATUS_TEXT_SELECT_DATA,

            isProofNeeded: false,

            selectedPerimeter: [],

            selectedBeneficiaries: [],
        };
    },

    computed: {
        reasons() {
            return this.unavailabilityReasons
                .filter((reason) => reason.is_constraint === NO)
                .map((reason) => ({
                    value: reason.indisponibilite,
                    text: `${reason.indisponibilite} - ${reason.lib_absence}`,
                }));
        },
    },

    created() {
        this.resetFilters();
    },

    methods: {
        resetFilters() {
            this.unavailabilityCreationDate = '';
            this.unavailabilityStartDate = '';
            this.unavailabilityEndDate = '';

            this.selectedReasons = [];
            this.selectedStatuses = [];
            this.isProofNeeded = false;

            this.selectedPerimeter = [];
            this.selectedBeneficiaries = [];

            this.updateFilters();
        },
        updateFilters() {
            const extractionFiltersData = {
                creationDate: this.unavailabilityCreationDate,
                startDate: this.unavailabilityStartDate,
                endDate: this.unavailabilityEndDate,

                selectedReasons: this.selectedReasons,
                selectedStatuses: this.selectedStatuses,
                isProofNeeded: this.isProofNeeded,

                selectedPerimeter: this.selectedPerimeter,
                selectedBeneficiaries: removeDuplicates(this.selectedBeneficiaries.flat()),
            };
            this.$emit('update-filters', extractionFiltersData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

p {
    margin-left: -16px;
    margin-bottom: 24px;
}

.extraction-filters {
    margin-bottom: 24px;
}

::v-deep .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}
</style>
