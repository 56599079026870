<template>
    <div>
        <Spinner v-if="isSpinnerDisplayed" />

        <UnavHistoricModal
            v-if="
                isUnavailabilityHistoricDisplayed &&
                unavailabilityHistoric &&
                unavailabilityHistoric.length
            "
            v-model="isUnavailabilityHistoricDisplayed"
            :unavailabilityHistoric="unavailabilityHistoric"
        />

        <v-row align="end" class="mt-6 mb-2" no-gutters>
            <div>
                Liste des résultats
                <span class="update-time" v-if="lastUpdate">
                    mise à jour le {{ lastUpdate.format('DD/MM/YYYY') }} à
                    {{ lastUpdate.format('HH:mm') }}
                </span>
            </div>

            <v-spacer />

            <v-btn
                tile
                depressed
                class="primary"
                @click="downloadExcel()"
                :disabled="isLoading || this.items.length === 0"
            >
                <v-icon class="mr-2 mt-1">mdi-tray-arrow-down</v-icon>
                <span class="bold">Extraire</span>
            </v-btn>
        </v-row>

        <v-data-table
            :headers="headers"
            :items="items"
            :loading="isLoading"
            loading-text="Chargement des données..."
            :options.sync="options"
            :footer-props="footerProps"
            must-sort
        >
            <template v-slot:no-data>
                <div class="pa-3">
                    <p class="bold">Aucune donnée à afficher.</p>
                    <p>
                        Définissez vos paramètres d'extraction puis cliquez sur "Rafraîchir les
                        résultats".
                    </p>
                </div>
            </template>

            <template v-slot:[`item.statut`]="{ item }">
                <td class="pl-1">
                    <StatusCellSection v-if="item" :item="item" />
                </td>
            </template>

            <template v-slot:[`item.date_created`]="{ item }">
                {{ item.date_created | formatDateTime }}
            </template>

            <template v-slot:[`item.nom`]="{ item }">{{ item.nom }} {{ item.prenom }}</template>

            <template v-slot:[`item.eor_cd`]="{ item }">
                {{ item.eor_cd }} - {{ item.lib_eor_cd }}
            </template>

            <template v-slot:[`item.date_debut`]="{ item }">
                {{ item.date_debut | formatDateTime }}
            </template>

            <template v-slot:[`item.date_fin`]="{ item }">
                {{ item.date_fin | formatDateTime }}
            </template>

            <template v-slot:[`item.indisponibilite`]="{ item }">
                {{ item.indisponibilite }} - {{ item.lib_absence }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="showUnavailabilityHistoricModal(item)">
                            <v-icon>mdi-history</v-icon>
                        </v-btn>
                    </template>
                    <span>Voir l'historique des actions</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapMutations } from 'vuex';
import moment from 'moment';

import { getExtractionUnavailabilities } from '@/api/extraction';
import unavailabilitiesApi from '@/api/unavailabilities';
import mapUnavailabilityToExtraction from '@/services/extractions';

import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import { API_MAX_LIMIT } from '@/constants/shared';
import EXTRACTION_TABLE_HEADERS from '@/constants/extraction';

import UnavHistoricModal from '@/components/UnavHistoricModal.vue';
import StatusCellSection from '@/components/sections/table/StatusCellSection.vue';
import Spinner from '@/components/Spinner.vue';

const fileName = 'ExtractionDataTable.vue';

export default {
    name: 'ExtractionDataTable.vue',

    components: {
        UnavHistoricModal,
        StatusCellSection,
        Spinner,
    },

    props: {
        filtersData: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            API_MAX_LIMIT,

            xlsxFileName: '',
            lastUpdate: undefined,
            isSpinnerDisplayed: false,

            headers: EXTRACTION_TABLE_HEADERS,
            items: [],

            isUnavailabilityHistoricDisplayed: false,
            unavailabilityHistoric: [],

            footerProps: {
                itemsPerPageText: 'Éléments par page : ',
                itemsPerPageOptions: [10, 20, 30],
                pageText: '{0}-{1} sur {2}',
            },

            options: {
                itemsPerPage: 10,
                sortBy: ['date_created'],
                sortDesc: [true],
            },
        };
    },

    watch: {
        filtersData() {
            this.getTableData();
        },
    },

    filters: {
        formatDateTime(date) {
            if (date) {
                return moment(date).format(DATE_TIME_FORMAT);
            }
            return '';
        },
    },

    methods: {
        ...mapMutations(['showErrorToast']),

        async getTableData() {
            let offset = 0;
            let limit = this.options.itemsPerPage;
            let responseData = {};
            this.items = [];
            this.showLoading();

            try {
                this.xlsxFileName = `eDispoExport${moment().format('DD_MM_YYYY')}.xlsx`;

                responseData = await getExtractionUnavailabilities(
                    this.filtersData.creationDate,
                    this.filtersData.startDate,
                    this.filtersData.endDate,
                    this.filtersData.selectedReasons,
                    this.filtersData.selectedStatuses,
                    this.filtersData.isProofNeeded,
                    this.filtersData.selectedPerimeter,
                    this.filtersData.selectedBeneficiaries,
                    this.options.sortBy,
                    this.options.sortDesc,
                    offset,
                    limit
                );
                this.items = responseData.items;

                while (responseData.hasMore) {
                    offset += limit;
                    limit = this.API_MAX_LIMIT;

                    // eslint-disable-next-line no-await-in-loop
                    responseData = await getExtractionUnavailabilities(
                        this.filtersData.creationDate,
                        this.filtersData.startDate,
                        this.filtersData.endDate,
                        this.filtersData.selectedReasons,
                        this.filtersData.selectedStatuses,
                        this.filtersData.isProofNeeded,
                        this.filtersData.selectedPerimeter,
                        this.filtersData.selectedBeneficiaries,
                        this.options.sortBy,
                        this.options.sortDesc,
                        offset,
                        limit
                    );
                    this.items = this.items.concat(responseData.items);
                }
                this.lastUpdate = moment();

                this.hideLoading();
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideLoading();
            }
        },
        downloadExcel() {
            const dataToExport = this.items.map(mapUnavailabilityToExtraction);
            const eDispoWorkSheet = XLSX.utils.json_to_sheet(dataToExport);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, eDispoWorkSheet, 'eDispo');
            XLSX.writeFile(workBook, this.xlsxFileName);
        },

        async showUnavailabilityHistoricModal(item) {
            try {
                this.isSpinnerDisplayed = true;
                this.unavailabilityHistoric = await unavailabilitiesApi.getUnavailabilityHistoric(
                    item.id
                );
                this.isUnavailabilityHistoricDisplayed = true;
                this.isSpinnerDisplayed = false;
            } catch (error) {
                this.isSpinnerDisplayed = false;
                this.showErrorToast({ error, fileName });
            }
        },

        showLoading() {
            this.$emit('update:isLoading', true);
        },
        hideLoading() {
            this.$emit('update:isLoading', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .header {
    background-color: $very-light-gray;
}
.update-time {
    padding-left: 8px;
    font-size: small;
    font-style: italic;
    color: $dark-gray;
}
</style>
