import moment from 'moment';
import { DATE_TIME_FORMAT } from '@/constants/dateFormat';

function formatDate(date) {
    return date ? moment(date).format(DATE_TIME_FORMAT) : null;
}

function mapUnavailabilityToExtraction(unavailability) {
    return {
        id: unavailability.id,
        gid_collaborateur: unavailability.gid_collaborateur,
        matricule: unavailability.matricule,
        nom: unavailability.nom,
        prenom: unavailability.prenom,
        code_agence: unavailability.eor_age,
        libelle_agence: unavailability.lib_eor_age,
        code_departement: unavailability.eor_dep,
        libelle_departement: unavailability.lib_eor_dep,
        code_equipe: unavailability.eor_eq,
        libelle_equipe: unavailability.lib_eor_eq,
        code_entite: unavailability.eor_cd,
        libelle_entite: unavailability.lib_eor_cd,
        indisponibilite: unavailability.indisponibilite,
        libelle_absence: unavailability.lib_absence,
        statut: unavailability.statut,
        date_debut: formatDate(unavailability.date_debut),
        date_fin: formatDate(unavailability.date_fin),
        decompte_absence: unavailability.decompte_absence,
        date_maj_solde: unavailability.last_balance_update,
        solde_rtt: unavailability.solde_rtt,
        solde_cp: unavailability.solde_cp,
        commentaire_declarant: unavailability.commentaire_declarant,
        commentaire_valideur: unavailability.commentaire_valideur,
        date_creation: formatDate(unavailability.date_created),
        nom_declarant: unavailability.nom_created,
        prenom_declarant: unavailability.prenom_created,
        date_modification: formatDate(unavailability.date_modification),
        nom_modification: unavailability.nom_modification,
        prenom_modification: unavailability.prenom_modification,
        gid_declarant: unavailability.user_created,
        justificatif_requis: unavailability.proof_needed,
        date_derniere_validation: formatDate(unavailability.dt_last_validation),
        assimile_temps_travail: unavailability.fl_assim_travail,
    };
}

export default mapUnavailabilityToExtraction;
