<template>
    <div>
        <ExtractionFilters
            v-if="perimeterEntities && unavailabilityReasons"
            :perimeterEntities="perimeterEntities"
            :perimeterPeople="perimeterPeople"
            :unavailabilityReasons="unavailabilityReasons"
            :isLoading="isLoading"
            @update-filters="onFiltersUpdate"
        />

        <ExtractionDataTable
            v-if="filtersData"
            :filtersData="filtersData"
            :isLoading.sync="isLoading"
        />

        <TableDataLegendSection />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import ExtractionFilters from '@/components/extraction/ExtractionFilters.vue';
import ExtractionDataTable from '@/components/extraction/ExtractionDataTable.vue';
import TableDataLegendSection from '@/components/sections/table/TableDataLegendSection.vue';

const perimeterNameSpace = createNamespacedHelpers('perimeter');
const unavailabilityReasonsNameSpace = createNamespacedHelpers('unavailabilityReasons');

const perimeterStore = {
    mapGetters: perimeterNameSpace.mapGetters,
    mapActions: perimeterNameSpace.mapActions,
};
const unavailabilityReasonsStore = {
    mapState: unavailabilityReasonsNameSpace.mapState,
    mapActions: unavailabilityReasonsNameSpace.mapActions,
};

export default {
    name: 'ExtractionPage',

    components: {
        ExtractionFilters,
        ExtractionDataTable,
        TableDataLegendSection,
    },

    data() {
        return {
            filtersData: undefined,
            isLoading: false,
        };
    },

    computed: {
        ...perimeterStore.mapGetters(['perimeterEntities', 'perimeterPeople']),
        ...unavailabilityReasonsStore.mapState(['unavailabilityReasons']),
    },

    async created() {
        await this.loadUnavailabilityReasons();
        await this.loadPerimeter();
    },

    methods: {
        ...perimeterStore.mapActions(['loadPerimeter']),
        ...unavailabilityReasonsStore.mapActions(['loadUnavailabilityReasons']),

        onFiltersUpdate(val) {
            this.filtersData = val;
        },
    },
};
</script>
